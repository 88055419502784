// MessageModal.jsx
import React from 'react';
import Modal from 'react-modal';
import './Modal.css';

const MessageModal = ({ isOpen, onRequestClose, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <h4>{message}</h4>
      <button className="modal-button" onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default MessageModal;
