import React, { useState, useEffect } from 'react';
import { fetchAllProducts, deleteProduct, fetchAllProductsByVendor ,addUpdateProduct} from '../util/Api'; // Adjust the path to your API utility
import Pagination from './Pagination'; // Import the Pagination component
import ProductForm from './ProductForm'; // Import the ProductForm component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Products.css';
import { useNavigate } from 'react-router-dom';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async (page = 1) => {
      try {
        let productsData;
        const user = JSON.parse(localStorage.getItem('user'));
        const userRole = user ? user.role : '';
        if (userRole === 'super_admin') {
          productsData = await fetchAllProducts(page - 1, 10); // Assuming 10 products per page
        } else {
          const vendorName = user.vendorName; // Assuming vendor name is stored in the user object
          productsData = await fetchAllProductsByVendor(page - 1, 10, vendorName); // Assuming 10 products per page
        }
        setProducts(productsData.content);
        setTotalPages(productsData.totalPages); // Adjust based on your API response
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    getProducts(currentPage);
  }, [currentPage]);

  const handleSearch = async () => {
    // Implement search functionality here if needed
  };

  const handleStatusToggle = async (product,productId, currentStatus) => {
    try {
      const newStatus = currentStatus === 'Y' ? 'N' : 'Y';
      setProducts(products.map(product => 
        product.id === productId ? { ...product, status: newStatus } : product
      ));
      const productData = await addUpdateProduct({ ...product, status: newStatus });

    } catch (error) {
      console.error('Error toggling product status:', error);
    }
  };

  const handleEdit = (product) => {
    navigate(`/products/${product.id}`);

  };
  const handleAddNew = () => {
    setCurrentProduct(null);
    setShowForm(true);
  };

  const handleFormSave = (formData) => {
    // Implement save functionality here
    setShowForm(false);
  };

  const handleFormCancel = () => {
    setShowForm(false);
  };

  const handleDelete = async (productIds) => {
    try {
      await deleteProduct(productIds);
      setProducts(products.filter(product => !productIds.includes(product.id)));
      setSelectedProductIds([]);
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSingleDelete = (productId) => {
    setProductToDelete(productId);
    setIsBulkDelete(false);
    setShowConfirmation(true);
  };

  const handleBulkDelete = () => {
    setIsBulkDelete(true);
    setShowConfirmation(true);
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProductIds((prevSelected) => {
      if (prevSelected.includes(productId)) {
        return prevSelected.filter(id => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allProductIds = products.map(product => product.id);
      setSelectedProductIds(allProductIds);
    } else {
      setSelectedProductIds([]);
    }
  };

  return (
    <div className="products">
      {showForm && (
        <ProductForm
          product={currentProduct}
          onSave={handleFormSave}
          onCancel={handleFormCancel}
          setShowForm={ setShowForm}
        />
      )}
      {!showForm && (
        <>
          <div className="products-header">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button onClick={handleSearch}>Search</button>
            {selectedProductIds.length > 0 && (
              <button className="delete-selected" onClick={handleBulkDelete}>Delete Selected</button>
            )}
            <button className="add-product" onClick={handleAddNew}>Add New Product</button>
           
          </div>
          <div className="products-table-container">
            <table className="products-table">
              <thead>
                <tr>
                  <th><input type="checkbox" onChange={handleSelectAll} /></th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Vendor</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map(product => (
                  <tr key={product.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedProductIds.includes(product.id)}
                        onChange={() => handleCheckboxChange(product.id)}
                      />
                    </td>
                    <td>
                      <img src={product.images?product.images.split(',')[0]:null} alt={product.productName} className="product-thumbnail" />
                    </td>
                    <td>{product.productName}</td>
                    <td>{product.vendorname}</td>
                    <td>{product.amount}</td>
                    <td>
                      <button 
                        className={`status-toggle-${product.status === 'Y' ? 'enabled' : 'disabled'}`}
                        onClick={() => handleStatusToggle(product,product.id, product.status)}
                      >
                        <FontAwesomeIcon icon={product.status === 'Y' ? faCheckCircle : faTimesCircle} />
                      </button>
                    </td>
                    <td>
                      <button className="action-button edit" onClick={() => handleEdit(product)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button className="action-button delete" onClick={() => handleSingleDelete(product.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </>
      )}

      {showConfirmation && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to delete {isBulkDelete ? 'these products?' : 'this product?'}</p>
            <button onClick={() => handleDelete(isBulkDelete ? selectedProductIds : [productToDelete])}>Yes</button>
            <button onClick={() => setShowConfirmation(false)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
