import React, { useState, useEffect } from 'react';
import { fetchAllUser } from '../util/Api';
import Pagination from '../product/Pagination'; // Adjust the import path if necessary
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './CustomerList.css';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const getUsers = async (page = 1) => {
      try {
        const usersData = await fetchAllUser(page - 1, 10); // Assuming 10 users per page
        setCustomers(usersData.content);
        setTotalPages(usersData.totalPages);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    getUsers(currentPage);
  }, [currentPage]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRowClick = (customer) => {
    localStorage.setItem('selectedCustomer', JSON.stringify(customer));
    navigate(`/customers/${customer.userId}`);
  };

  return (
    <div className="customer-list">
      <h2>Customers</h2>
      <div className="customer-header">
        <input
          type="text"
          placeholder="Search customers..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="customer-table-container">
        <table className="customer-table">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map(customer => (
              <tr key={customer.userId} onClick={() => handleRowClick(customer)}>
                <td>{customer.userId}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td className={`status-${customer.active ? 'active' : 'inactive'}`}>
                  {customer.active ? 
                    <FontAwesomeIcon icon={faCheckCircle} className="status-true" /> :
                    <FontAwesomeIcon icon={faTimesCircle} className="status-false" />
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default CustomerList;
