import React, { useState, useEffect } from 'react';
import './ProfileEditPopup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateManagerProfile, sendOtp, confirmOtp, changePassword } from '../util/Api';

const ProfileEditPopup = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [otpConfirmed, setOtpConfirmed] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUserData(data);
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      await updateManagerProfile(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      setModalMessage('Profile updated successfully!');
      setShowModal(true);
      setIsEditing(false);
    } catch (error) {
      setModalMessage('Error updating profile. Please try again.');
      setShowModal(true);
    }
  };

  const handleCancelEdit = () => {
    const data = JSON.parse(localStorage.getItem('user'));
    setUserData(data);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSendOtp = async () => {
    try {
      await sendOtp(userData.staffid);
      setModalMessage('OTP sent successfully!');
      setShowModal(true);
      setIsChangingPassword(true);
      setResendEnabled(false);
      setTimeout(() => setResendEnabled(true), 60000);
    } catch (error) {
      setModalMessage('Error sending OTP. Please try again.');
      setShowModal(true);
    }
  };

  const handleConfirmOtp = async () => {
    try {
      await confirmOtp(userData.staffid, otp);
      setModalMessage('OTP confirmed. You can now set a new password.');
      setShowModal(true);
      setOtpConfirmed(true);
    } catch (error) {
      setModalMessage('Invalid OTP. Please try again.');
      setShowModal(true);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setModalMessage('Passwords do not match. Please try again.');
      setShowModal(true);
      return;
    }
    if (newPassword.length < 8 || newPassword.length > 12) {
      setModalMessage('Password must be between 8 and 12 characters.');
      setShowModal(true);
      return;
    }
    try {
      await changePassword(userData.staffid, newPassword);
      setModalMessage('Password updated successfully!');
      setShowModal(true);
      setIsChangingPassword(false);
      setOtpConfirmed(false);
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setModalMessage('Error updating password. Please try again.');
      setShowModal(true);
    }
  };

  if (!userData) return <div>Loading...</div>;

  return (
    <div className="profile-edit-popup">
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
         
            <h3>{modalMessage}</h3>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
      <div className="profile-edit-popup-content">
        {isEditing ? (
          <div>
            <h3>Edit Profile</h3>
            <form className="edit-profile-form" onSubmit={handleUpdateProfile}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" value={userData.name} onChange={handleChange} />

              <label htmlFor="vendorname">Vendor Name:</label>
              <input type="text" id="vendorname" name="vendorname" value={userData.vendorname} onChange={handleChange} />

              <label htmlFor="staffid">Staff ID:</label>
              <input type="text" id="staffid" name="staffid" value={userData.staffid} onChange={handleChange} />

              <label htmlFor="password">Password:</label>
              <input type="password" id="password" name="password" value={userData.password} onChange={handleChange} disabled />

              <label htmlFor="address">Address:</label>
              <textarea id="address" name="address" value={userData.address} onChange={handleChange} />

              <div className="edit-profile-buttons">
                <button type="submit">Update</button>
                <button type="button" onClick={handleCancelEdit}>Cancel</button>
              </div>
            </form>
          </div>
        ) : isChangingPassword ? (
          otpConfirmed ? (
            <div>
              <h3>Change Password</h3>
              <label htmlFor="newPassword">New Password:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label htmlFor="confirmNewPassword">Confirm New Password:</label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <div className="edit-profile-buttons">
                <button type="button" onClick={handleChangePassword}>
                Change Password
                </button>
                <button type="button" onClick={() => setIsChangingPassword(false)}>Cancel</button>
              </div>
            </div>
          ) : (
            <div className="otp-popup">
              <button className="close-icon" onClick={() => setIsChangingPassword(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h3>Enter OTP</h3>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                maxLength="4"
              />
              <div className="edit-profile-buttons">
                <button type="button" onClick={handleConfirmOtp}>Confirm OTP</button>
                <button type="button" onClick={handleSendOtp} disabled={!resendEnabled}>Resend OTP</button>
              </div>
            </div>
          )
        ) : (
          <div className="profile-card">
            <button className="edit-icon" onClick={handleEditClick}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <h3>Profile</h3>
            <p><strong>Name:</strong> {userData.name}</p>
            <p><strong>Vendor Name:</strong> {userData.vendorname}</p>
            <p><strong>Staff ID:</strong> {userData.staffid}</p>
            <p><strong>Address:</strong> {userData.address}</p>
            <button onClick={handleSendOtp}>Change Password</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileEditPopup;
