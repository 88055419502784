import React, { useState, useEffect } from 'react';
import './ProductInfoForm.css';

const ProductInfoForm = ({ productInfo, updateProductInfo }) => {
  const [additionalInfo, setAdditionalInfo] = useState(productInfo?.Additional || []);
  const [technicalInfo, setTechnicalInfo] = useState(productInfo?.Technical || []);

  useEffect(() => {
    setAdditionalInfo(productInfo?.Additional || []);
    setTechnicalInfo(productInfo?.Technical || []);
  }, [productInfo]);

  const handleAdditionalInfoChange = (index, key, value) => {
    const updatedAdditionalInfo = additionalInfo.map((info, i) =>
      i === index ? { ...info, [key]: value } : info
    );
    setAdditionalInfo(updatedAdditionalInfo);
    updateProductInfo({ ...productInfo, Additional: updatedAdditionalInfo });
  };

  const handleTechnicalInfoChange = (index, key, value) => {
    const updatedTechnicalInfo = technicalInfo.map((info, i) =>
      i === index ? { ...info, [key]: value } : info
    );
    setTechnicalInfo(updatedTechnicalInfo);
    updateProductInfo({ ...productInfo, Technical: updatedTechnicalInfo });
  };

  const handleAddAdditionalInfo = () => {
    setAdditionalInfo([...additionalInfo, { item: '', name: '' ,heading:'Additional'}]);
  };

  const handleAddTechnicalInfo = () => {
    setTechnicalInfo([...technicalInfo, { item: '', name: '',heading:'Technical' }]);
  };

  const handleRemoveAdditionalInfo = (index) => {
    const updatedAdditionalInfo = additionalInfo.filter((_, i) => i !== index);
    setAdditionalInfo(updatedAdditionalInfo);
    updateProductInfo({ ...productInfo, Additional: updatedAdditionalInfo });
  };

  const handleRemoveTechnicalInfo = (index) => {
    const updatedTechnicalInfo = technicalInfo.filter((_, i) => i !== index);
    setTechnicalInfo(updatedTechnicalInfo);
    updateProductInfo({ ...productInfo, Technical: updatedTechnicalInfo });
  };

  return (
    <div className="size-form">
      <h3>Additional Information</h3>
      {additionalInfo.map((info, index) => (
        <div key={index} className="size-form-row">
          <input
            type="text"
            value={info.item}
            onChange={(e) => handleAdditionalInfoChange(index, 'item', e.target.value)}
            placeholder="Key"
          />
          <input
            type="text"
            value={info.name}
            onChange={(e) => handleAdditionalInfoChange(index, 'name', e.target.value)}
            placeholder="Value"
          />
          <button type="button" className="close-icon" onClick={() => handleRemoveAdditionalInfo(index)}>
            &#x2715;
          </button>
        </div>
      ))}
      <button type="button" className="add-size-button" onClick={handleAddAdditionalInfo}>
        + Add Additional Info
      </button>
      <h3>Technical Information</h3>
      {technicalInfo.map((info, index) => (
        <div key={index} className="size-form-row">
          <input
            type="text"
            value={info.item}
            onChange={(e) => handleTechnicalInfoChange(index, 'item', e.target.value)}
            placeholder="Key"
          />
          <input
            type="text"
            value={info.name}
            onChange={(e) => handleTechnicalInfoChange(index, 'name', e.target.value)}
            placeholder="Value"
          />
          <button type="button" className="close-icon" onClick={() => handleRemoveTechnicalInfo(index)}>
            &#x2715;
          </button>
        </div>
      ))}
      <button type="button" className="add-size-button" onClick={handleAddTechnicalInfo}>
        + Add Technical Info
      </button>
    </div>
  );
};

export default ProductInfoForm;
