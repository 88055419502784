import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './SalesLineChart.css';

const dataToday = [
  { hour: '00:00', orders: 5 },
  { hour: '03:00', orders: 12 },
  { hour: '06:00', orders: 15 },
  { hour: '09:00', orders: 10 },
  { hour: '12:00', orders: 20 },
  { hour: '15:00', orders: 18 },
  { hour: '18:00', orders: 22 },
  { hour: '21:00', orders: 17 },
];

const dataWeek = [
  { day: 'Mon', orders: 30 },
  { day: 'Tue', orders: 45 },
  { day: 'Wed', orders: 60 },
  { day: 'Thu', orders: 55 },
  { day: 'Fri', orders: 50 },
  { day: 'Sat', orders: 65 },
  { day: 'Sun', orders: 40 },
];

const dataMonth = [
  { month: 'Week 1', orders: 100 },
  { month: 'Week 2', orders: 120 },
  { month: 'Week 3', orders: 150 },
  { month: 'Week 4', orders: 130 },
];

const dataOverall = [
  { year: '2022', orders: 500 },
  { year: '2023', orders: 600 },
  { year: '2024', orders: 800 },
  { year: '2025', orders: 750 },
];

const SalesLineChart = () => {
  const [activeTab, setActiveTab] = useState('totalSale');
  const [activeTimePeriod, setActiveTimePeriod] = useState('today');

  let data;
  switch (activeTimePeriod) {
    case 'today':
      data = dataToday;
      break;
    case 'week':
      data = dataWeek;
      break;
    case 'month':
      data = dataMonth;
      break;
    case 'overall':
      data = dataOverall;
      break;
    default:
      data = [];
  }

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleChangeTimePeriod = (timePeriod) => {
    setActiveTimePeriod(timePeriod);
  };

  return (
    <div className="graph-card">
      <div className="tab-container">
      <select value={activeTimePeriod} onChange={(e) => handleChangeTimePeriod(e.target.value)}>
          <option value="today">Today</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="overall">Overall</option>
        </select>
        <button className={`tab-button ${activeTab === 'totalSale' && 'selected'}`} onClick={() => handleChangeTab('totalSale')}>Total Sale</button>
        <button className={`tab-button ${activeTab === 'ongoingOrders' && 'selected'}`} onClick={() => handleChangeTab('ongoingOrders')}>Ongoing Orders</button>
        <button className={`tab-button ${activeTab === 'deliveredOrders' && 'selected'}`} onClick={() => handleChangeTab('deliveredOrders')}>Delivered Orders</button>
            </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="hour" axisLine={{ stroke: '#ccc', strokeWidth: 1 }} />
          <YAxis axisLine={{ stroke: '#ccc', strokeWidth: 1 }} />
          <Tooltip />
          <Line type="monotone" dataKey="orders" stroke="#0f62fe" strokeWidth={3} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesLineChart;