import React, { useState, useEffect } from 'react';
import Pagination from '../product/Pagination';
import { fetchCollection, addCollection, deleteCollection, deleteFile, uploadFile } from '../util/Api'; // Adjust API functions accordingly
import './Collections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Collections = ({}) => {
  const [collections, setCollections] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCollectionIds, setSelectedCollectionIds] = useState([]);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    url: "",
    name: "",
    collectionDisplayName: "",
    status: "Y",
    imageFile: null,
  });
  const [showModal, setShowModal] = useState(false);
  const canModifyCollections = localStorage.getItem("role") === 'super_admin';
  const imgUrl="https://octavia.nyc3.digitaloceanspaces.com/";
  const fetchData = async (page = 1) => {
    try {
      const response = await fetchCollection(page - 1, 10); // Assuming 10 items per page
      setCollections(response.content);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]); // Trigger useEffect when currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = async () => {
    try {
      // Perform search operation based on searchTerm
    } catch (error) {
      console.error('Error searching collections:', error);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allCollectionIds = collections.map(collection => collection.id);
      setSelectedCollectionIds(allCollectionIds);
    } else {
      setSelectedCollectionIds([]);
    }
  };

  const handleCheckboxChange = (collectionId) => {
    setSelectedCollectionIds((prevSelected) => {
      if (prevSelected.includes(collectionId)) {
        return prevSelected.filter(id => id !== collectionId);
      } else {
        return [...prevSelected, collectionId];
      }
    });
  };

  const handleStatusToggle = async (collectionId, currentStatus) => {
    try {
      const newStatus = currentStatus === 'Y' ? 'N' : 'Y';
      const updatedCollections = collections.map(collection =>
        collection.id === collectionId ? { ...collection, status: newStatus } : collection
      );
      setCollections(updatedCollections);
      if (canModifyCollections) {
        await addCollection(updatedCollections.find(collection => collection.id === collectionId)); // Update status via API call
      }
    } catch (error) {
      console.error('Error toggling collection status:', error);
    }
  };

  const handleEdit = (collection) => {
    if (canModifyCollections) {
      setFormData({
        id: collection.id,
        url: collection.url,
        name: collection.name,
        collectionDisplayName: collection.collectionDisplayName,
        status: collection.status,
        imageFile: null,
      });
      setShowModal(true);
    }
  };

  const handleSingleDelete = (collectionId) => {
    if (canModifyCollections) {
      setCollectionToDelete(collectionId);
      setIsBulkDelete(false);
      setShowConfirmation(true);
    }
  };

  const handleBulkDelete = () => {
    if (canModifyCollections) {
      setIsBulkDelete(true);
      setShowConfirmation(true);
    }
  };

  const handleAddNew = () => {
    if (canModifyCollections) {
      setFormData({
        id: "",
        url: "",
        name: "",
        collectionDisplayName: "",
        status: "Y",
        imageFile: null,
      });
      setShowModal(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (formData.id) {
        // Update existing collection
        if (formData.imageFile) {
          const filename = formData.url.substring(formData.url.lastIndexOf("/") + 1);

          await deleteFile(filename);
          const imageUrl = await uploadFile(formData.imageFile);
          response = await addCollection({ ...formData, url: imgUrl+imageUrl });
        } else {
          response = await addCollection(formData);
        }
      } else {
        // Add new collection
        const imageUrl = await uploadFile(formData.imageFile);
        response = await addCollection({ ...formData, url: imgUrl+imageUrl });
      }
      setFormData({
        id: "",
        url: "",
        name: "",
        collectionDisplayName: "",
        status: "Y",
        imageFile: null,
      });
      setShowModal(false);
      setCollections([...collections, response]); // Update collections state with the new or updated collection
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDelete = async (id, url) => {
    try {
      if (url) {
        const filename = url.substring(url.lastIndexOf("/") + 1);
        await deleteFile(filename);
      }
      await deleteCollection(id);
      setCollections(collections.filter(collection => !id.includes(collection.id)));
      setSelectedCollectionIds([]);
      setShowConfirmation(false);
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "imageFile") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="collections">
      <div className="products-header">
        <input 
          type="text" 
          placeholder="Search collections..." 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
        <button onClick={handleSearch}>Search</button>
        {canModifyCollections && selectedCollectionIds.length > 0 && (
          <button className="delete-selected" onClick={handleBulkDelete}>Delete Selected</button>
        )}
        {canModifyCollections && (
          <button className="add-product" onClick={handleAddNew}>Add New Collection</button>
        )}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <form onSubmit={handleSubmit} className="collection-form">
              <div className="form-group">
                <label htmlFor="imageFile">Image</label>
                <input
                  type="file"
                  name="imageFile"
                  onChange={handleChange}
                  required={!formData.id}
                />
              </div>
              <div className="form-group">
                <label htmlFor="url">URL</label>
                <input
                  type="text"
                  name="url"
                  placeholder="URL"
                  value={formData.url}
                  onChange={handleChange}
                  disabled={!canModifyCollections || !!formData.id}
                />
              </div>
              <div className="form-group">
                <label htmlFor="collectionDisplayName">Collection Display Name</label>
                <input
                  type="text"
                  name="collectionDisplayName"
                  placeholder="Collection Display Name"
                  value={formData.collectionDisplayName}
                  onChange={handleChange}
                  disabled={!canModifyCollections}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  disabled={!canModifyCollections}
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select name="status" value={formData.status} onChange={handleChange} disabled={!canModifyCollections}>
                  <option value="Y">Active</option>
                  <option value="N">Inactive</option>
                </select>
              </div>
              <div className="form-buttons">
                <button type="submit" disabled={!canModifyCollections}>
                  {formData.id ? "Update" : "Create"} Collection
                </button>
                <button type="button" onClick={handleCloseModal} disabled={!canModifyCollections}>
                  <FontAwesomeIcon icon={faTimesCircle} /> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="products-table-container">
        <table className="products-table">
          <thead>
            <tr>
              <th><input type="checkbox" onChange={handleSelectAll} /></th>
              <th>Image</th>
              <th>Name</th>
              <th>Display Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {collections.map(collection => (
              <tr key={collection.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedCollectionIds.includes(collection.id)}
                    onChange={() => handleCheckboxChange(collection.id)}
                    disabled={!canModifyCollections}
                  />
                </td>
                <td>
                  <img src={collection.url} alt={collection.name} className="product-thumbnail" />
                </td>
                <td>{collection.name}</td>
                <td>{collection.collectionDisplayName}</td>
                <td>
                  <button 
                    className={`status-toggle-${collection.status === 'Y' ? 'enabled' : 'disabled'}`}
                    onClick={() => handleStatusToggle(collection.id, collection.status)}
                    disabled={!canModifyCollections}
                  >
                    <FontAwesomeIcon icon={collection.status === 'Y' ? faCheckCircle : faTimesCircle} />
                  </button>
                </td>
                <td>
                  <button className="action-button edit" onClick={() => handleEdit(collection)} disabled={!canModifyCollections}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button className="action-button delete" onClick={() => handleSingleDelete(collection.id)} disabled={!canModifyCollections}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      
      {showConfirmation && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to delete {isBulkDelete ? 'these collections?' : 'this collection?'}</p>
            <button onClick={() => handleDelete(isBulkDelete ? selectedCollectionIds : [collectionToDelete])}>Yes</button>
            <button onClick={() => setShowConfirmation(false)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collections;

