import axios from "axios";

// api.js
const BASE_URL = 'https://api.mememall.tv'; // Replace with your API base URL
//const BASE_URL = 'http://localhost:8080'; // Replace with your API base URL
//
const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});



const token = localStorage.getItem('token');


export const getTokenFromApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: 'ecom@template.com',
        password: 'ecom123',
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const jwtToken = data.jwtToken;

    // Store token in local storage for future use
    localStorage.setItem('token', jwtToken);

    return jwtToken;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

// Function to login the user
export const loginUser = async (staffid, password) => {
  try {
    const token = localStorage.getItem('token') || await getTokenFromApi();
    
    const response = await fetch(`${BASE_URL}/ad-manager-login/${staffid}/${password}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.text(); 
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

// Function to get the user details
export const getUser = async (staffid) => {
  try {
    const token = localStorage.getItem('token') || await getTokenFromApi();

    const response = await fetch(`${BASE_URL}/ad-get-manager/${staffid}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};
export const registerUser = async (email,password,name,referredBy) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-user-registration`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,name,referredBy
        })
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const otpConfirmation = async (email,otp) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-user-otp-confirmation/${email}/${otp}`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const resendOtp = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-user-resend-otp/${email}`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchCollection = async (offset,pagesize) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-collections/${offset}/${pagesize}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const addCollection = async (banner) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-create-collection`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(banner)  
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const deleteCollection = async(id)=>{
  try {
    const response = await fetch(`${BASE_URL}/ad-delete-collection/${id}`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const addCart=async(email,sku,qty)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-add-cart`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          sku,qty
        })
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const removeItem=async(email,sku)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-add-cart/${email}/${sku}`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
       
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchSlider = async () => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-banner`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

export const clearCart=async(email)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-add-cart/${email}`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
       
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const viewCart = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-view-cart/${email}`, {
      method: 'POST',  

        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const placeOrder=async(order,orderItems)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-place-order`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          order,orderItems
        })
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const getOrders = async (email,offset,pagesize) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-orders/${offset}/${pagesize}/${email}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

export const getAddress = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-addresses/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const addNewAddress=async(editableAddress)=>{
  console.log(editableAddress);
  try {
    const response = await fetch(`${BASE_URL}/ui-add-address`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }, body: JSON.stringify(
          editableAddress
        )
       
      });
      
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const deleteAddress=async(id)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-add-address/${id}`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
       
      });
      
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

export const addWishList=async(userId,productId)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-add-wishlist`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          productId
        })       
      });
      
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}

export const getWishList=async(email)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-get-wishlist/${email}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}
export const searchproduct=async(keyword)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-search-products/${keyword}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
}
export const stripePay=async(amnt,currency,method)=>{
  try {
    const response = await fetch(`${BASE_URL}/stripe/pay`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amnt,
          currency,method
        })   
       
      });
      
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const selectedFilterOptions=async(filterOption)=>{
  try {
    const response = await fetch(`${BASE_URL}/ui-filter-products`, {
      method: 'POST',  
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(filterOption)  
       
      });
      
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
//-------------ADMIN API

//-------PRODUCTS

export const fetchAllProducts = async (offset,pagesize) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-products/${offset}/${pagesize}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchAllProductsByVendor = async (offset,pagesize,vendorname) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-products-vendor/${offset}/${pagesize}/${vendorname}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchProduct = async (id) => {
  try {
      const response = await fetch(`${BASE_URL}/ui-get-product/${id}`, {
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return await response.json();
  } catch (error) {
      console.error('Error fetching menu data:', error);
      throw error;
  }
};
export const addUpdateProduct = async (product) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-add-product`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
      body: JSON.stringify(product)  
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const addUpdateProductSize = async (productsize) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-add-size`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
      body: JSON.stringify(productsize)  
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const deleteProductSize = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-delete-size/${id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.text();

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const deleteProduct = async (ids) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-delete-product`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(ids)  

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.text();

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const fetchAllOrders = async (offset,pagesize) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-orders/${offset}/${pagesize}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const fetchOrderById = async (ordercode) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-orders/${ordercode}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

export const updateOrderDeliveryStatus = async (order) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-update-order`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(order)  

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const fetchMenuData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-category`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};
export const updateCategory = async (menu) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-create-category`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(menu)  

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const deleteCategory = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-delete-category/${id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.text();

  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
export const fetchAllOrdersVendor = async (offset,pagesize,vendorname) => {
  try {
    const response = await fetch(`${BASE_URL}/ui-get-orders-vendor/${offset}/${pagesize}/${vendorname}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching menu data:', error);
    throw error;
  }
};

// Api.js
export const fetchAllVendors = async (offset, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-all-manager/${offset}/${pageSize}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};
export const fetchManager = async (staffid) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-manager/${staffid}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};
export const fetchAllManagersByVendor = async (vendorname) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-managers/${vendorname}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};
export const fetchAllUser= async (offset, pageSize) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-get-users/${offset}/${pageSize}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};

export const createSlider = async (sliderData) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-add-banner`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(sliderData)
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error creating slider:', error);
    throw error;
  }
};

export const deleteSlider = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/ad-delete-banner/${id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error deleting slider:', error);
    throw error;
  }
};
export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("multipartFile", file);
    const response = await axios.post(`${BASE_URL}/ad-file-upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error uploading file: " + error.message);
  }
};

export const deleteFile = async (name) => {

  try {
    const response = await fetch(`${BASE_URL}/ad-file-delete/${name}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  } catch (error) {
    throw new Error("Error deleting file: " + error.message);
  }
};
export const addProductInformation = async (productInfo) => {

  try {
    const response = await fetch(`${BASE_URL}/ad-productinfo`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(productInfo)


    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  } catch (error) {
    throw new Error("Error deleting file: " + error.message);
  }
};
export const fetcCollectionNames= async () => {
  try {
    const response = await fetch(`${BASE_URL}/ad-collection-names`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};

export const updateManagerProfile = async (profile) => {

  try {
    const response = await fetch(`${BASE_URL}/ad-manager-update`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(profile)


    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  } catch (error) {
    throw new Error("Error deleting file: " + error.message);
  }
};
export const sendOtp = async (staffid) => {

  try {
    const response = await fetch(`${BASE_URL}/ad-manager-resend-otp/${staffid}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  } catch (error) {
    throw new Error("Error deleting file: " + error.message);
  }
};
export const confirmOtp = async (staffid,otp) => {

  try {
    const response = await fetch(`${BASE_URL}/ad-manager-otp-confirmation/${staffid}/${otp}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  } catch (error) {
    throw new Error("Error deleting file: " + error.message);
  }
};
export const changePassword = async (staffid,password) => {

  try {
    const response = await fetch(`${BASE_URL}/ad-manager-update-password/${staffid}/${password}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  } catch (error) {
    throw new Error("Error deleting file: " + error.message);
  }
};
export const getOrderCounts= async () => {
  try {
    const response = await fetch(`${BASE_URL}/get-order-counts`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};
export const getOrderCountsVendor= async (vendorname) => {
  try {
    const response = await fetch(`${BASE_URL}/get-order-counts/${vendorname}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching vendor data:', error);
    throw error;
  }
};