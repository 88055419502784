// OrderDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderById, updateOrderDeliveryStatus } from '../util/Api'; // Adjust the import path if necessary
import './OrderDetails.css';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState('');
  const [showUpdatePopup, setShowUpdatePopup] = useState(false); // State to control the visibility of the update popup

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const data = await fetchOrderById(orderId);
        setOrderDetails(data);
        setDeliveryStatus(data.order.deliveryStatus || '');
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleDeliveryStatusChange = (event) => {
    setDeliveryStatus(event.target.value);
  };

  const handleUpdateDeliveryStatus = async () => {
    try {
      await updateOrderDeliveryStatus(orderDetails);
      setShowUpdatePopup(true); // Show the update popup after a successful update
    } catch (error) {
      console.error('Error updating delivery status:', error);
    }
  };

  const closePopup = () => {
    setShowUpdatePopup(false); // Close the update popup
  };

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  const { order, orderItems } = orderDetails;

  return (
    <div className="order-details">
      <div className="order-details-container">
        <div className="order-card">
          <h3>Order Information</h3>
          <p><strong>Order ID:</strong> {order.ordercode}</p>
          <p><strong>Date:</strong> {order.date}</p>
          <p><strong>Total Price:</strong> ${order.price}</p>
          <p><strong>Payment Status:</strong> {order.paymentStatus || 'N/A'}</p>
          <p><strong>Delivery Charge:</strong> ${order.deliveryCharge}</p>
          <p><strong>Payment Mode:</strong> {order.paymentmode || 'N/A'}</p>
          <p><strong>Status:</strong> {order.status || 'N/A'}</p>
          <p><strong>Updated Date:</strong> {order.updatedate}</p>
          <p><strong>Items Count:</strong> {order.item}</p>
        </div>

        <div className="order-card">
          <h3>Address Information</h3>
          <p><strong>Customer Name:</strong> {order.name}</p>
          <p><strong>Email:</strong> {order.email}</p>
          <p><strong>Phone:</strong> {order.phone}</p>
          <p><strong>Address:</strong> {order.flatNo}, {order.address1}, {order.address2}, {order.zip}, {order.country}</p>
        </div>
      </div>

      <div className="delivery-status">
        <h3>Update Delivery Status</h3>
        <select value={deliveryStatus} onChange={handleDeliveryStatusChange}>
          <option value="" disabled>Select status</option>
          <option value="Pending">Pending</option>
          <option value="Shipped">Shipped</option>
          <option value="Delivered">Delivered</option>
          <option value="Cancelled">Cancelled</option>
        </select>
        <button onClick={handleUpdateDeliveryStatus}>Update</button>
      </div>

      <h3>Order Items</h3>
      <div className="order-items">
        {orderItems.map(item => (
          <div key={item.id} className="order-item">
            <img src={item.imgUrl.split(',')[0]} alt={item.name} className="order-item-img" />
            <div className="order-item-details">
              <p><strong>Product Name:</strong> {item.name}</p>
              <p><strong>Quantity:</strong> {item.quantity}</p>
              <p><strong>Unit Price:</strong> ${item.unitprice}</p>
              <p><strong>Subtotal:</strong> ${item.subt}</p>
              <p><strong>Color:</strong> {item.color}</p>
              <p><strong>SKU:</strong> {item.sku}</p>
              <p><strong>Vendor Name:</strong> {item.vendorname}</p>
              <p><strong>Offer:</strong> {item.offer}%</p>
              <p><strong>Description:</strong> {item.description}</p>
            </div>
          </div>
        ))}
      </div>

      {showUpdatePopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Delivery Status Updated Successfully!</h3>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
