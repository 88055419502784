import React, { useState, useEffect } from 'react';
import { fetchCollection, deleteCollection } from '../util/Api'; // Assuming you have API functions for CRUD operations
import './Navigation.css';
import Menu from '../menu/Menu';
import Collections from './Collections';

const Navigation = () => {
  const [collections, setCollections] = useState([]);
  const [selectedTab, setSelectedTab] = useState('categories'); // 'categories' or 'collections'

  useEffect(() => {
    const fetchCollectionsData = async () => {
      try {
        const data = await fetchCollection(0, 10); // Fetch collections with offset and page size
        setCollections(data);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    fetchCollectionsData();
  }, []);

  const handleDeleteCollection = async (id) => {
    try {
      await deleteCollection(id); // Make API call to delete collection
      // Update state or refetch collections after deletion
    } catch (error) {
      console.error('Error deleting collection:', error);
    }
  };

  return (
    <div className="collections">
      <div className="tabs">
        <button 
          className={selectedTab === 'categories' ? 'active' : ''}
          onClick={() => setSelectedTab('categories')}
        >
          Menu
        </button>
        <button 
          className={selectedTab === 'collections' ? 'active' : ''}
          onClick={() => setSelectedTab('collections')}
        >
          Collections
        </button>
      </div>
      {selectedTab === 'categories' && (
        <div className="content">
          <h2>Menu</h2>
          <Menu/>
        </div>
      )}
      {selectedTab === 'collections' && (
        <div className="content">
          <h2>Collections</h2>
          <Collections/>
        </div>
      )}
    </div>
  );
};

export default Navigation;
