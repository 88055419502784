import React, { useState, useEffect } from "react";
import { fetchSlider, createSlider, deleteSlider, uploadFile, deleteFile } from "../util/Api"; // Adjust the import path if necessary
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus, faCheckCircle, faTimesCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./SliderAdmin.css";

const SliderAdmin = () => {
  const [sliders, setSliders] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    weburl: "",
    mobileurl: "",
    sometext: "",
    status: "Y",
    imageFile: null,
  });
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [sliderToDelete, setSliderToDelete] = useState(null);
  const imgUrl = "https://octavia.nyc3.digitaloceanspaces.com/";

  useEffect(() => {
    loadSliders();
  }, []);

  const loadSliders = async () => {
    try {
      const data = await fetchSlider();
      setSliders(data);
    } catch (error) {
      console.error("Error loading sliders:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "imageFile") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = formData.weburl; // Default to weburl if no new file is selected
      if (formData.imageFile) {
        imageUrl = await uploadFile(formData.imageFile);
      }
      await createSlider({ ...formData, weburl: imgUrl + imageUrl });
      // Reset form and reload sliders
      setFormData({
        id: "",
        weburl: "",
        mobileurl: "",
        sometext: "",
        status: "Y",
        imageFile: null,
      });
      setIsFormVisible(false);
      loadSliders();
      setMessage("Slider successfully saved.");
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("Error saving slider.");
      setTimeout(() => setMessage(""), 3000);
    }
  };

  const handleEdit = (slider) => {
    setFormData({ ...slider, imageFile: null }); // Reset imageFile to null
    setIsFormVisible(true);
  };

  const handleCancel = () => {
    setFormData({
      id: "",
      weburl: "",
      mobileurl: "",
      sometext: "",
      status: "Y",
      imageFile: null,
    });
    setIsFormVisible(false);
  };

  const handleDelete = async () => {
    try {
      const { id, weburl } = sliderToDelete;
      if (weburl) {
        // Delete image file when deleting slider
        const filename = weburl.substring(weburl.lastIndexOf("/") + 1);
        await deleteFile(filename);
      }
      // Remove the deleted slider from the state
      setSliders(sliders.filter((slider) => slider.id !== id));
      await deleteSlider(id);
      setMessage("Slider successfully deleted.");
      setIsDeleteConfirmVisible(false);
      setSliderToDelete(null);
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Error deleting slider:", error);
      setMessage("Error deleting slider.");
      setIsDeleteConfirmVisible(false);
      setSliderToDelete(null);
      setTimeout(() => setMessage(""), 3000);
    }
  };

  const toggleStatus = async (id) => {
    try {
      const sliderToUpdate = sliders.find((slider) => slider.id === id);
      const updatedStatus = sliderToUpdate.status === "Y" ? "N" : "Y";
      await createSlider(id, { ...sliderToUpdate, status: updatedStatus });
      loadSliders();
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const handleAddNew = () => {
    setFormData({
      id: "",
      weburl: "",
      mobileurl: "",
      sometext: "",
      status: "Y",
      imageFile: null,
    });
    setIsFormVisible(true);
  };

  const confirmDelete = (slider) => {
    setSliderToDelete(slider);
    setIsDeleteConfirmVisible(true);
  };

  return (
    <div className="slider-admin">
      <h2>Manage Sliders</h2>
      <button className="add-slider-button" onClick={handleAddNew}>
        <FontAwesomeIcon icon={faPlus} /> Add Slider
      </button>
      {message && (
        <div className="popup-message">
          <span>{message}</span>
        </div>
      )}
      {isFormVisible && (
        <form onSubmit={handleSubmit} className="slider-form">
          <input
            type="file"
            name="imageFile"
            onChange={handleChange}
            required={!formData.id}
          />
          <input
            type="text"
            name="weburl"
            placeholder="Web URL"
            value={formData.weburl}
            onChange={handleChange}
            disabled={!!formData.id}
          />
          <input
            type="text"
            name="mobileurl"
            placeholder="Mobile URL"
            value={formData.mobileurl}
            onChange={handleChange}
          />
          <input
            type="text"
            name="sometext"
            placeholder="Some Text"
            value={formData.sometext}
            onChange={handleChange}
            required
          />
          <select name="status" value={formData.status} onChange={handleChange}>
            <option value="Y">Active</option>
            <option value="N">Inactive</option>
          </select>
          <div className="form-buttons">
            <button type="submit">
              {formData.id ? "Update" : "Create"} Slider
            </button>
            <button type="button" onClick={handleCancel}>
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
          </div>
        </form>
      )}

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Image</th>
              <th>Web URL</th>
              <th>Some Text</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sliders.map((slider) => (
              <tr key={slider.id}>
                <td>{slider.id}</td>
                <td>
                  <img
                    src={slider.weburl}
                    alt={slider.sometext}
                    className="slider-image"
                  />
                </td>
                <td>{slider.weburl}</td>
                <td>{slider.sometext}</td>
                <td onClick={() => toggleStatus(slider.id)}>
                  {slider.status === "Y" ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="status-icon-green"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="status-icon-red"
                    />
                  )}
                </td>
                <td className="action-icons">
                  <button onClick={() => handleEdit(slider)}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="status-icon-green"
                    />
                  </button>
                  <button onClick={() => confirmDelete(slider)}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="status-icon-red"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteConfirmVisible && (
        <div className="popup-confirm">
          <p>Are you sure you want to delete this slider?</p>
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setIsDeleteConfirmVisible(false)}>No</button>
        </div>
      )}
    </div>
  );
};

export default SliderAdmin;
