import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import logo from '../../assets/logo.png';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
  };

  return (
    <AppBar position="sticky" className="header">
      <Toolbar className="header-toolbar">
        <Link to="/" className="header__logo">
          <IconButton edge="start" color="inherit" aria-label="logo">
            <img src={logo} alt="Amazon Logo" />
          </IconButton>
        </Link>
        <Typography variant="h6" className="header-content">
          Hi {JSON.parse(localStorage.getItem('user')).name}
        </Typography>
        <Button color="inherit" onClick={handleLogout} className="account-button">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
