import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header/Header'; // Correct path to Header component
import Sidebar from '../sidebar/Sidebar'; // Correct path to Sidebar component
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <Header />
      <div className="home-container">
        <Sidebar />
        <main className="content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Home;
