// ConfirmModal.jsx
import React from 'react';
import Modal from 'react-modal';
import './Modal.css';

const ConfirmModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <h4>Are you sure you want to delete this menu?</h4>
      <div className="modal-buttons">
        <button className="modal-button modal-button-confirm" onClick={onConfirm}>Yes</button>
        <button className="modal-button modal-button-cancel" onClick={onRequestClose}>No</button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
