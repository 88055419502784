import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import SalesLineChart from "./SalesLineChart";
import { getOrderCounts, getOrderCountsVendor } from "../util/Api";

// Styled Card component with scrollable content
const StyledCard = styled(Card)(({ theme }) => ({
  height: '300px', 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', 
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
  color: '#fff',
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[10],
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flex: '1 1 auto',
  overflowY: 'auto',
  paddingTop: theme.spacing(2), 
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const FixedHeader = styled(Typography)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
  zIndex: 1,
  padding: "10px",
}));

const Dashboard = () => {
  const [order, setOrder] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!user) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
    if (!order.length) {
      const getOrderCount = async () => {
        setOrder(
          user?.role === "super_admin"
            ? await getOrderCounts()
            : await getOrderCountsVendor(user?.vendorname)
        );
      };
      getOrderCount();
    }
  }, [order.length, user]);

  return (
    <div>
      {/* Top Orders Section */}
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Overall Orders</Typography>
              <Typography variant="h4">{order[0]}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Cancelled Orders</Typography>
              <Typography variant="h4">{order[1]}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Ongoing Orders</Typography>
              <Typography variant="h4">{order[2]}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Completed Orders</Typography>
              <Typography variant="h4">{order[3]}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Grid Section with Headings */}
      <Grid container spacing={4}>
        {/* Left Grid with 4 Cards */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Management Sections
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Collections / Menu</FixedHeader>
                <StyledCardContent>
                  <List>
                    <ListItem>
                      <ListItemText primary="1. Firstly need to add collections ex.T-shirts / Winter / Summer ." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="2. On Click of status column icon enable / disable the collection same way edit and delet will work" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="3. After succefully added the collections create menu which will be displyed as a menu bar in website ." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="4. While adding menu map your collection associated with this category" />
                    </ListItem>
                  </List>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Products</FixedHeader>
                <StyledCardContent>
                  <List>
                    <ListItem>
                      <ListItemText primary="1. Prepare product images in high resolution." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="2. Write detailed product descriptions." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="3. Set pricing and stock availability." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="4. Click 'Submit' to upload the product." />
                    </ListItem>
                  </List>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Orders</FixedHeader>
                <StyledCardContent>
                  <List>
                    <ListItem>
                      <ListItemText primary="1. All the orders ill be appeared here" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="2. On click of order details of the order will be shown" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="3. In details screen can / update the status of the order" />
                    </ListItem>
                  </List>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Customers</FixedHeader>
                <StyledCardContent>
                  <List>
                    <ListItem>
                      <ListItemText primary="1. Will have all the customers." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="2. Where can enable and disable the customer" />
                    </ListItem>
                  </List>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Vendors</FixedHeader>
                <StyledCardContent>
                  <List>
                    <ListItem>
                      <ListItemText primary="1. Will have all the vendors here." />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="2. On click of particular vendor we can view / manage vendors and staffs" />
                    </ListItem>
                  </List>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Slides</FixedHeader>
                <StyledCardContent>
                  <Typography>
                    Add / update / delete the sliders here whch will be listed on Webiste
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Manage Vendors</FixedHeader>
                <StyledCardContent>
                  <Typography>
                    Tools and resources to manage vendor relationships and accounts.
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard>
                <FixedHeader variant="h6">Profile Settings</FixedHeader>
                <StyledCardContent>
                  <Typography>
                    Update your profile and account settings.
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Grid with Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Sales Overview
          </Typography>
          <SalesLineChart />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
