import React, { useState } from 'react';
import './SizeForm.css';
import { deleteProductSize } from '../util/Api';

const SizeForm = ({ sizes, setFormData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sizeToDelete, setSizeToDelete] = useState(null);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      sizes: prevData.sizes.map((size, i) =>
        i === index ? { ...size, [name]: value } : size
      )
    }));
  };

  const handleAddSize = () => {
    setFormData(prevData => ({
      ...prevData,
      sizes: [...prevData.sizes, { name: '', sku: '', stocks: 0, price: 0.0 }]
    }));
  };

  const handleConfirmDelete = async () => {
    if (sizeToDelete !== null) {
      const size = sizes[sizeToDelete];
      if (size.id) {
        try {
          await deleteProductSize(size.id);
        } catch (error) {
          console.error("Error deleting size:", error);
        }
      }

      setFormData(prevData => ({
        ...prevData,
        sizes: prevData.sizes.filter((_, i) => i !== sizeToDelete)
      }));
      setSizeToDelete(null);
      setIsModalOpen(false);
    }
  };

  const handleRemoveSize = (index) => {
    setSizeToDelete(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSizeToDelete(null);
  };

  return (
    <div className="size-form">
      <h3>Sizes</h3>
      {sizes.map((size, index) => (
        <div key={index} className="size-form-row">
          <input
            type="text"
            name="name"
            placeholder="Size Name"
            value={size.name}
            onChange={(e) => handleChange(index, e)}
          />
          <input
            type="text"
            name="sku"
            placeholder="SKU"
            value={size.sku}
            onChange={(e) => handleChange(index, e)}
          />
          <input
            type="number"
            name="stocks"
            placeholder="Stocks"
            value={size.stocks}
            onChange={(e) => handleChange(index, e)}
          />
          <input
            type="number"
            name="price"
            placeholder="Price"
            value={size.price}
            onChange={(e) => handleChange(index, e)}
          />
          <button type="button" className="close-icon" onClick={() => handleRemoveSize(index)}>
            &#x2715;
          </button>
        </div>
      ))}
      <button type="button" className="add-size-button" onClick={handleAddSize}>
        + Add Size
      </button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure?</h3>
            <p>Deleting this size cannot be undone. Do you want to proceed?</p>
            <button onClick={handleConfirmDelete}>Yes</button>
            <button onClick={handleCloseModal}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SizeForm;
