// src/components/util/PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const isAuthenticated = () => {
  // Add your authentication logic here (e.g., check local storage or context)
  return !!localStorage.getItem('authToken');
};

const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
