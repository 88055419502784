import React, { useState, useEffect } from 'react';
import { fetchMenuData, updateCategory, deleteCategory,fetcCollectionNames } from '../util/Api'; // Adjust the path according to your structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import MessageModal from '../util/MessageModal';
import ConfirmModal from '../util/ConfirmModal';
import './Menu.css';

const Menu = () => {
  const [menus, setMenus] = useState([]);
  const [menuName, setMenuName] = useState('');
  const [selectedMainMenu, setSelectedMainMenu] = useState('');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingMenu, setEditingMenu] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [menuToDelete, setMenuToDelete] = useState(null);
  const[collectionNames,setcollection] =useState([])
  const [selectedCollection, setSelectedCollection] = useState('');
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const data = await fetchMenuData();
        setMenus(data);
        const collection=await fetcCollectionNames();
        setcollection(collection);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };
    fetchData();
  }, []);

  const handleAddOrUpdateMenu = async () => {
    if (!menuName) {
      setMessage('Please enter a menu name.');
      setIsMessageModalOpen(true);
      return;
    }
    try {
      if (isEditMode && editingMenu) {
        const id = selectedSubMenu ? selectedSubMenu : selectedMainMenu ? selectedMainMenu : null;
        await updateCategory({ mainCategoryId: editingMenu.mainCategoryId, name: menuName, id: id ,collections: selectedCollection });
        setMessage('Menu updated successfully.');
      } else {
        await updateCategory({ name: menuName, collections: selectedCollection }); // Pass selected collection
        setMessage('Menu added successfully.');
      }
      const updatedMenus = await fetchMenuData();
      setMenus(updatedMenus);
      handleCancel();
    } catch (error) {
      console.error('Error adding/updating menu:', error);
      setMessage('Error adding/updating menu.');
    }
    setIsMessageModalOpen(true);
  };
  const handleEditMenu = (menu, type) => {
    setIsEditMode(true);
    setMenuName(menu.name);
    setEditingMenu(menu);
    setSelectedCollection(menu.collections)
    if (type === 'main') {
      setSelectedMainMenu('');
      setSelectedSubMenu('');
    } else if (type === 'sub') {
      setSelectedMainMenu(menu.mainCategoryId);
      setSelectedSubMenu(menu.id);
    }
  };

  const handleDeleteMenu = async () => {
    if (menuToDelete.subcategories || menuToDelete.childcategories) {
      setMessage('Selected Menu has Sub/Child Category unable to proceed.');
      setIsMessageModalOpen(true);
      return;
    }
    try {
      await deleteCategory(menuToDelete.mainCategoryId);
      setMessage('Deleted Successfully.');
      const updatedMenus = await fetchMenuData();
      setMenus(updatedMenus);
    } catch (error) {
      console.error('Error deleting menu:', error);
      setMessage('Error deleting menu.');
    }
    setIsMessageModalOpen(true);
    setIsConfirmModalOpen(false);
  };

  const handleDeleteClick = (menu) => {
    setMenuToDelete(menu);
    setIsConfirmModalOpen(true);
  };

  const handleCancel = () => {
    setMenuName('');
    setSelectedMainMenu('');
    setSelectedSubMenu('');
    setIsEditMode(false);
    setEditingMenu(null);
  };

  return (
    <div className="collections">
      <div className="menu-controls">
        <input
          type="text"
          placeholder="Menu Name"
          value={menuName}
          onChange={(e) => setMenuName(e.target.value)}
        />
        <select
          value={selectedCollection}
          onChange={(e) => setSelectedCollection(e.target.value)}
        >
          <option value="">Select Collection</option>
          {collectionNames.map((collection) => ( // Populate dropdown with collection names
            <option key={collection} value={collection}>
              {collection}
            </option>
          ))}
        </select>
        <select
          value={selectedMainMenu}
          onChange={(e) => setSelectedMainMenu(e.target.value)}
        >
          <option value="">Select Main Category</option>
          {menus.map((menu) => (
            <option key={menu.mainCategoryId} value={menu.mainCategoryId}>
              {menu.name}
            </option>
          ))}
        </select>
        {selectedMainMenu && (
          <select
            value={selectedSubMenu}
            onChange={(e) => setSelectedSubMenu(e.target.value)}
          >
            <option value="">Select Sub Category</option>
            {menus
              .find((menu) => menu.mainCategoryId === parseInt(selectedMainMenu))
              ?.subcategories?.map((subMenu) => (
                <option key={subMenu.mainCategoryId} value={subMenu.mainCategoryId}>
                  {subMenu.name}
                </option>
              ))}
          </select>
        )}
        <div className="button-group">
          <button className="md-btn" onClick={handleCancel}>Cancel</button>
          <button className="md-btn" onClick={handleAddOrUpdateMenu}>
            {isEditMode ? 'Update' : 'Add'}
          </button>
        </div>
      </div>
      <div className="menu-cards">
        {menus.map((menu) => (
          <div key={menu.mainCategoryId} className="menu-card">
            <div
              className="menu-card-header"
              onClick={() => setSelectedMainMenu(menu.mainCategoryId === selectedMainMenu ? '' : menu.mainCategoryId)}
            >
              <span>{menu.name}</span>
              <div>
                <button className='btn' onClick={() => handleEditMenu(menu, 'main')}>
                  <FontAwesomeIcon icon={faEdit} className="status-icon-green" />
                </button>
                <button className='btn' onClick={() => handleDeleteClick(menu)}>
                  <FontAwesomeIcon icon={faTrash} className="status-icon-red" />
                </button>
              </div>
            </div>
            {menu.mainCategoryId === selectedMainMenu && (
              <div className="menu-card-body">
                {menu.subcategories?.map((subMenu) => (
                  <div key={subMenu.id} className="menu-card">
                    <div
                      className="menu-card-header"
                      onClick={() => setSelectedSubMenu(subMenu.id === selectedSubMenu ? '' : subMenu.id)}
                    >
                      <span>{subMenu.name}</span>
                      <div>
                        <button className='btn' onClick={() => handleEditMenu(subMenu, 'sub')}>
                          <FontAwesomeIcon icon={faEdit} className="status-icon-green" />
                        </button>
                        <button className='btn' onClick={() => handleDeleteClick(subMenu)}>
                          <FontAwesomeIcon icon={faTrash} className="status-icon-red" />
                        </button>
                      </div>
                    </div>
                    {subMenu.id === selectedSubMenu && (
                      <div className="menu-card-body">
                        {subMenu.childcategories?.map((childMenu) => (
                          <div key={childMenu.id} className="menu-card">
                            <div
                              className="menu-card-header"
                              onClick={() => handleEditMenu(childMenu, 'child')}
                            >
                              <span>{childMenu.name}</span>
                              <div>
                                <button className='btn' onClick={() => handleEditMenu(childMenu, 'child')}>
                                  <FontAwesomeIcon icon={faEdit} className="status-icon-green" />
                                </button>
                                <button className='btn' onClick={() => handleDeleteClick(childMenu)}>
                                  <FontAwesomeIcon icon={faTrash} className="status-icon-red" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <MessageModal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        message={message}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleDeleteMenu}
      />
    </div>
  );
};

export default Menu;
