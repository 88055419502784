import React, { useState, useEffect } from 'react';
import { fetchAllOrders, fetchAllOrdersVendor } from '../util/Api';
import { useNavigate } from 'react-router-dom';
import Pagination from '../product/Pagination'; // Adjust the import path if necessary
import './Orders.css';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ordersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user ? user.role : '';

  useEffect(() => {
    const fetchOrders = async (page = 1) => {
      try {
        let ordersData;
        if (userRole === 'super_admin') {
          ordersData = await fetchAllOrders(page - 1, ordersPerPage);
        } else {
          const vendorName = user.vendorName; // Assuming vendor name is stored in the user object
          ordersData = await fetchAllOrdersVendor(page - 1, ordersPerPage, vendorName);
        }
        setOrders(ordersData.content);
        setTotalPages(ordersData.totalPages);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders(currentPage);
  }, [currentPage, userRole, ordersPerPage]);

  const handleSearch = async () => {
    // Implement search functionality here if needed
  };

  const handleCheckboxChange = (orderId) => {
    if (orderId === 'all') {
      if (selectedOrders.length === orders.length) {
        setSelectedOrders([]);
      } else {
        setSelectedOrders(orders.map(order => order.id));
      }
    } else {
      setSelectedOrders(prevSelected =>
        prevSelected.includes(orderId)
          ? prevSelected.filter(id => id !== orderId)
          : [...prevSelected, orderId]
      );
    }
  };

  const handleRowClick = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  const isSelected = (orderId) => selectedOrders.includes(orderId);

  const getStatusClass = (status, type) => {
    if (type === 'fulfillment') {
      if (status === 'fulfilled') return 'status-green';
      return 'status-amber';
    }
    if (type === 'delivery') {
      if (status === 'delivered') return 'status-green';
      if (status === 'cancelled') return 'status-red';
      return 'status-amber';
    }
    return '';
  };

  return (
    <div className="orders">
      <h2>Order Management</h2>
      <div className="orders-header">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="orders-table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  id="selectAll"
                  checked={selectedOrders.length === orders.length}
                  onChange={() => handleCheckboxChange('all')}
                />
              </th>
              <th>Order ID</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Total</th>
              <th>Payment Status</th>
              <th>Fulfillment Status</th>
              <th>Items</th>
              <th>Delivery Status</th>
              <th>Delivery Mode</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => {
              const paymentStatus = order.paymentStatus || 'COD';
              const deliveryStatus = order.deliveryStatus || 'TBA';
              const fulfillmentStatus = deliveryStatus === 'delivered' ? 'fulfilled' : 'unfulfilled';
              const deliveryMode = deliveryStatus === 'TBA' ? 'TBA' : order.deliveryMode;

              return (
                <tr key={order.ordercode} onClick={() => handleRowClick(order.ordercode)}>
                  <td>
                    <input
                      type="checkbox"
                      checked={isSelected(order.id)}
                      onChange={() => handleCheckboxChange(order.ordercode)}
                    />
                  </td>
                  <td>{order.ordercode}</td>
                  <td>{order.date}</td>
                  <td>{order.name}</td>
                  <td>{order.price}</td>
                  <td>{paymentStatus}</td>
                  <td className={getStatusClass(fulfillmentStatus, 'fulfillment')}>
                    {fulfillmentStatus}
                  </td>
                  <td>{order.item}</td>
                  <td className={getStatusClass(deliveryStatus, 'delivery')}>
                    {deliveryStatus}
                  </td>
                  <td>{deliveryMode}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default Orders;
