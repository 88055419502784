import React, { useState, useEffect } from 'react';
import { fetchAllVendors } from '../util/Api';
import Pagination from '../product/Pagination'; // Adjust the import path if necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './VendorList.css';

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const getVendors = async (page = 1) => {
      try {
        const vendorsData = await fetchAllVendors(page - 1, 10); // Assuming 10 vendors per page
        setVendors(vendorsData.content);
        setTotalPages(vendorsData.totalPages);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    getVendors(currentPage);
  }, [currentPage]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredVendors = vendors.filter(vendor =>
    vendor.vendorname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRowClick = (vendor) => {
    localStorage.setItem('selectedVendor', JSON.stringify(vendor));
    navigate(`/vendors/${vendor.id}`);
  };

  return (
    <div className="vendor-list">
      <h2>Vendors</h2>
      <div className="vendor-header">
        <input
          type="text"
          placeholder="Search vendors..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="vendor-table-container">
        <table className="vendor-table">
          <thead>
            <tr>
              <th>Vendor ID</th>
              <th>Vendor Name</th>
              <th>Staff ID</th>
              <th>Status</th>
              <th>Name</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {filteredVendors.map(vendor => (
              <tr key={vendor.id} onClick={() => handleRowClick(vendor)}>
                <td>{vendor.id}</td>
                <td>{vendor.vendorname}</td>
                <td>{vendor.staffid}</td>
                <td className={`status-${vendor.status}`}>
                  {vendor.status ? (
                    <FontAwesomeIcon icon={faCheckCircle} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} color="red" />
                  )}
                </td>
                <td>{vendor.name}</td>
                <td>{vendor.address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default VendorList;
