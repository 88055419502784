import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Dashboard, Collections, ShoppingBag, ShoppingCart, People, Store, Slideshow, AccountCircle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import logo from '../../assets/logo.png';

const useStyles = makeStyles({
  drawer: {
    width: 200,
    '& .MuiDrawer-paper': {
      width: 200,
      backgroundColor: '#232f3e',
      color: 'white',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    marginTop:'20px !important',
    '&:hover': {
      backgroundColor: '#575757',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0',
  },
  logo: {
    width: '42px',
    height: '42px',
  },
});

const Sidebar = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user ? user.role : '';

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
    >
      <div className={classes.logoContainer}>
        <img src={logo} alt="Amazon Logo" className={classes.logo} />
      </div>
      <List>
        <ListItem button className={classes.listItem} component={Link} to="/">
          <ListItemIcon><Dashboard style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/collections">
          <ListItemIcon><Collections style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Collections" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/products">
          <ListItemIcon><ShoppingBag style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/orders">
          <ListItemIcon><ShoppingCart style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/customers">
          <ListItemIcon><People style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItem>
        {userRole === 'super_admin' && (
          <ListItem button className={classes.listItem} component={Link} to="/vendors">
            <ListItemIcon><Store style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Vendors" />
          </ListItem>
        )}
        {userRole !== 'super_admin' && (
          <ListItem button className={classes.listItem} component={Link} to={`/vendors/${user.id}`}>
            <ListItemIcon><Store style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Managers" />
          </ListItem>
        )}
        <ListItem button className={classes.listItem} component={Link} to="/sliders">
          <ListItemIcon><Slideshow style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Sliders" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/profile">
          <ListItemIcon><AccountCircle style={{ color: 'white' }} /></ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
