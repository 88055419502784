import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrders } from '../util/Api'; // Adjust the import path if necessary
import Pagination from '../product/Pagination'; // Adjust the import path if necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './CustomerDetail.css';

const CustomerDetail = () => {
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const customerData = JSON.parse(localStorage.getItem('selectedCustomer'));
    setCustomer(customerData);

    const fetchOrders = async (page = 1) => {
      try {
        const ordersData = await getOrders(customerData.email, page - 1, 10); // Assuming 10 orders per page
        setOrders(ordersData || []); // Ensure orders is always an array
        setTotalPages(ordersData.totalPages || 1); // Ensure totalPages has a default value
      } catch (error) {
        console.error('Error fetching orders:', error);
        setOrders([]); // Set to an empty array on error
      }
    };

    if (customerData) {
      fetchOrders(currentPage);
    }
  }, [id, currentPage]);

  // Function to handle order click
  const handleOrderClick = (order) => {
    navigate(`/orders/${order.order.ordercode}`);
  };

  if (!customer) return <div>Loading...</div>;

  return (
    <div className="customer-detail">
      <h2>{customer.name}</h2>
      <p>Email: {customer.email}</p>
      <p>Status: {customer.active ? <FontAwesomeIcon icon={faCheckCircle} className="status-icon status-icon-active" /> : <FontAwesomeIcon icon={faTimesCircle} className="status-icon status-icon-inactive" />}</p>
      <h3>Orders</h3>
      <div className="orders-table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Total</th>
              <th>Payment Status</th>
              <th>Fulfillment Status</th>
              <th>Items</th>
              <th>Delivery Status</th>
              <th>Delivery Mode</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.order.ordercode} onClick={() => handleOrderClick(order)}> {/* Attach click handler */}
                <td>{order.order.ordercode}</td>
                <td>{order.order.date}</td>
                <td>{order.order.name}</td>
                <td>{order.order.price}</td>
                <td>{order.order.paymentStatus || 'COD'}</td>
                <td className={getStatusClass(order.order.fulfillmentStatus, 'fulfillment')}>
                  {order.order.fulfillmentStatus}
                </td>
                <td>{order.order.item}</td>
                <td className={getStatusClass(order.order.deliveryStatus, 'delivery')}>
                  {order.order.deliveryStatus || 'TBA'}
                </td>
                <td>{order.order.deliveryMode || 'TBA'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      {selectedOrder && (
        <div className="order-details">
          <h3>Selected Order Details</h3>
          <p>Order ID: {selectedOrder.ordercode}</p>
          <p>Date: {selectedOrder.date}</p>
          <p>Customer: {selectedOrder.name}</p>
          <p>Total: {selectedOrder.price}</p>
          <p>Payment Status: {selectedOrder.paymentStatus || 'COD'}</p>
          <p>Fulfillment Status: {selectedOrder.fulfillmentStatus}</p>
          <p>Items: {selectedOrder.item}</p>
          <p>Delivery Status: {selectedOrder.deliveryStatus || 'TBA'}</p>
          <p>Delivery Mode: {selectedOrder.deliveryMode || 'TBA'}</p>
        </div>
      )}
    </div>
  );
};

const getStatusClass = (status, type) => {
  if (type === 'fulfillment') {
    if (status === 'fulfilled') return 'status-green';
    return 'status-amber';
  }
  if (type === 'delivery') {
    if (status === 'delivered') return 'status-green';
    if (status === 'cancelled') return 'status-red';
    return 'status-amber';
  }
  return '';
};

export default CustomerDetail;
