// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/home/Home';
import Products from './components/product/Products';
import Orders from './components/order/Orders';
import OrderDetails from './components/order/OrderDetails';
import Menu from './components/menu/Menu';
import Navigation from './components/collections/Navigation';
import Login from './components/account/Login';
import Register from './components/account/Register'; // Ensure you have a Register component
import PrivateRoute from './components/util/PrivateRoute';
import './App.css';
import VendorList from './components/vendor/VendorList';
import VendorDetails from './components/vendor/VendorDetails';
import Dashboard from './components/dashboard/Dashboard';
import CustomerList from './components/customer/CustomerList';
import CustomerDetail from './components/customer/CustomerDetail';
import SliderAdmin from './components/slider/SliderAdmin';
import ProductForm from './components/product/ProductForm';
import ProfileEditPopup from './components/header/ProfileEditPopup';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />}>
              <Route index element={<Dashboard/>} />
              <Route path="products" element={<Products />} />
              <Route path="products/:id" element={<ProductForm />} />
              <Route path="orders" element={<Orders />} />
              <Route path="orders/:orderId" element={<OrderDetails />} />
              <Route path="menu" element={<Menu />} />
              <Route path="collections" element={<Navigation />} />
              <Route path="vendors" element={<VendorList />} />
              <Route path="customers" element={<CustomerList />} />
              <Route path="customers/:id" element={<CustomerDetail />} />
              <Route path="/vendors/:id" element={<VendorDetails />} />
              <Route path="sliders" element={<SliderAdmin />} />
              <Route path="profile" element={<ProfileEditPopup />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
