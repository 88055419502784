import React, { useEffect, useState } from 'react';
import { fetchManager, fetchAllManagersByVendor } from '../util/Api';
import { useParams } from 'react-router-dom';
import './VendorDetails.css';

const VendorDetails = () => {
  const [vendor, setVendor] = useState(null);
  const [managers, setManagers] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const storedVendor = localStorage.getItem('selectedVendor');
    if (storedVendor) {
      setVendor(JSON.parse(storedVendor));
    } else {
      const fetchVendorDetails = async (staffid) => {
        try {
          const vendorData = await fetchManager(staffid);
          setVendor(vendorData);
          localStorage.setItem('selectedVendor', JSON.stringify(vendorData));
        } catch (error) {
          console.error('Error fetching vendor details:', error);
        }
      };

      fetchVendorDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (vendor) {
      const fetchManagers = async (vendorname) => {
        try {
          const managersData = await fetchAllManagersByVendor(vendorname);
          setManagers(managersData);
        } catch (error) {
          console.error('Error fetching managers:', error);
        }
      };

      fetchManagers(vendor.vendorname);
    }
  }, [vendor]);

  const handleStatusToggle = (managerId) => {
    setManagers(managers.map(manager => {
      if (manager.id === managerId) {
        if (manager.id === vendor.id) {
          alert('Primary account cannot be disabled. Contact your admin.');
        } else {
          manager.status = !manager.status;
        }
      }
      return manager;
    }));
  };

  if (!vendor) {
    return <div>Loading...</div>;
  }

  return (
    <div className="vendor-details">
      <h2>Vendor Details</h2>
      <div className="vendor-card">
        <p><strong>Vendor ID:</strong> {vendor.id}</p>
        <p><strong>Vendor Name:</strong> {vendor.vendorname}</p>
        <p><strong>Staff ID:</strong> {vendor.staffid}</p>
        <p><strong>Status:</strong> {vendor.status ? 'Active' : 'Inactive'}</p>
        <p><strong>Name:</strong> {vendor.name}</p>
        <p><strong>Address:</strong> {vendor.address}</p>
      </div>
      <h3>Managers</h3>
      <div className="manager-list">
        {managers.map(manager => (
          <div key={manager.id} className="manager-card">
            <div className="manager-card-header">
              <p><strong>ID:</strong> {manager.id}</p>
              <button
                className={`status-toggle ${manager.status ? 'active' : 'inactive'}`}
                onClick={() => handleStatusToggle(manager.id)}
                disabled={manager.id === vendor.id}
                title={manager.id === vendor.id ? "This is your primary account." : ""}
              >
                {manager.status ? 'Active' : 'Inactive'}
              </button>
            </div>
            <p><strong>Name:</strong> {manager.name}</p>
            <p><strong>Vendor Name:</strong> {manager.vendorname}</p>
            <p><strong>Email:</strong> {manager.staffid}</p>
            <p><strong>Role:</strong> {manager.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VendorDetails;
