import React, { useState, useEffect, useRef } from 'react';
import './ProductForm.css';
import SizeForm from './SizeForm';
import ProductInfoForm from './ProductInfoForm'; // Adjust path based on your folder structure
import { fetchProduct, addUpdateProduct, addUpdateProductSize, uploadFile, deleteFile, addProductInformation ,fetcCollectionNames} from '../util/Api';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const ProductForm = ({ product, onCancel, onSave, setShowForm }) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    productName: '',
    offer: '',
    imageUrl: '',
    description: '',
    description1: '',
    minQty: '',
    deliveryTime: '',
    brand: '',
    collectionNames: '',
    images: [],
    sku: '',
    status: 'Y',
    sizes: [],
    productInfo: {
      Additional: [],
      Technical: [],
    },
    imagesToDelete: [],
    vendorname: ''
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const collectionInputRef = useRef(null);
  const [vendorname, setVendorName] = useState(null);
  const[collections,setcollection] =useState([])
  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const imgUrl = "https://octavia.nyc3.digitaloceanspaces.com/";

  useEffect(() => {
    if(!collections.length){
      const populateCollectionName=async()=>{
        setcollection(await fetcCollectionNames());

      }
      populateCollectionName();
    }

    const vendorname = JSON.parse(localStorage.getItem('user')).vendorname;
    if(!vendorname){
      setVendorName(vendorname)
    }
    setVendorName()
    const populateFormData = async () => {
      if (!product) {
        try {
          const productData = await fetchProduct(id);
          setFormData({
            productName: productData.productName || '',
            offer: productData.offer || '',
            imageUrl: productData.images[0] || '',
            description: productData.description || '',
            description1: productData.description1 || '',
            minQty: productData.minQty || '',
            deliveryTime: productData.deliveryTime || '',
            brand: productData.brand || '',
            collectionNames: productData.collectionNames || '',
            images: productData.images || [],
            sku: productData.sku || '',
            status: productData.status || 'Y',
            sizes: productData.sizes || [],
            productInfo: productData.productInfo || {},
            imagesToDelete: [],
            vendorname:productData.vendorname
          });
        } catch (error) {
          console.error('Error fetching product data:', error);
        }
      }
    };

    populateFormData();
  }, [product, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setHasChanges(true);
  };

  const handleAddToCollection = (collection) => {
    if (collection && !formData.collectionNames.includes(collection)) {
      const updatedCollection = formData.collectionNames ? `${formData.collectionNames},${collection}` : collection;
      setFormData(prevState => ({
        ...prevState,
        collectionNames: updatedCollection,
      }));
      setHasChanges(true);
    }
  };

  const handleRemoveFromCollection = (collectionToRemove) => {
    const updatedCollection = formData.collectionNames
      .split(',')
      .filter(collection => collection !== collectionToRemove)
      .join(',');
    setFormData(prevState => ({
      ...prevState,
      collectionNames: updatedCollection,
    }));
    setHasChanges(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && formData.collectionNames) {
      const collections = formData.collectionNames.split(',');
      collections.pop();
      const updatedCollection = collections.join(',');
      setFormData(prevState => ({
        ...prevState,
        collectionNames: updatedCollection,
      }));
      setHasChanges(true);
    }
  };

  const handleCollectionChange = (e) => {
    const collection = e.target.value;
    if (collection) {
      handleAddToCollection(collection);
      e.target.value = '';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.images.length === 0) {
      setSnackbarMessage('Please upload at least one image.');
      setSnackbarOpen(true);
      return;
    }
    setShowConfirmationModal(true);
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedImages = [];
    for (const file of files) {
      uploadedImages.push(file);
    }
    setUploadedImages(uploadedImages);
    setFormData(prevState => ({
      ...prevState,
      images: [...prevState.images, ...uploadedImages.map(img => URL.createObjectURL(img))]
    }));
    setHasChanges(true);
  };

  const handleRemoveImage = (imageUrl) => {
    const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    setFormData(prevState => ({
      ...prevState,
      imagesToDelete: [...prevState.imagesToDelete, filename],
      images: prevState.images.filter((image) => image !== imageUrl),
    }));
    setHasChanges(true);
  };

  const handleSaveProduct = async () => {
    setIsLoading(true);
    setNotification('');
    try {
      const uploadedImageUrls = [];
      for (const uploadedImage of uploadedImages) {
        const response = await uploadFile(uploadedImage);
        uploadedImageUrls.push(imgUrl + response);
      }

      for (const filename of formData.imagesToDelete) {
        await deleteFile(filename);
      }

      const allImages = [...formData.images, ...uploadedImageUrls.filter(img=>!img.includes('blob'))];
      const productBody = {
        id: id,
        productName: formData.productName || '',
        offer: formData.offer || '',
        imageUrl: formData.imageUrl || '',
        description: formData.description || '',
        description1: formData.description1 || '',
        minQty: formData.minQty || '',
        deliveryTime: formData.deliveryTime || '',
        brand: formData.brand || '',
        collectionNames: formData.collectionNames || '',
        images: allImages.join(',') || '',
        sku: formData.sku || '',
        status: formData.status || 'Y',
        vendorname:formData.vendorname||vendorname
      };

      const productData = await addUpdateProduct(productBody);

      if (productData.id && formData.sizes.length > 0) {
        const sizeData = await addUpdateProductSize(
          formData.sizes.map((size) => ({ ...size, pid: productData.id }))
        );
      }
      if (formData.productInfo) {
        const productInfo = [];
        for(let index=0;index<formData.productInfo.Additional.length;index++){
          productInfo.push(formData.productInfo.Additional[index])
        }
        for(let index=0;index<formData.productInfo.Technical.length;index++){
          productInfo.push(formData.productInfo.Technical[index])
        }
        const productInformation = await addProductInformation(productInfo.map((info) => ({ ...info, productId: productData.id })));
      }
      setHasChanges(false);
      setNotification('Product saved/updated successfully.');
    } catch (error) {
      console.error('Error saving product data:', error);
      setNotification('There was an issue with saving the data.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmation = (confirmed) => {
    setShowConfirmationModal(false);
    if (confirmed) {
      handleSaveProduct();
    }
  };
  const handleProductInfoChange = (updatedProductInfo) => {
    setFormData(prevData => ({
      ...prevData,
      productInfo: updatedProductInfo
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };
  return (
    <div className="product-form">
    <h2>{product ? 'Edit Product' : 'Add New Product'}</h2>
    <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name</label>
          <input
            type="text"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Offer</label>
          <input
            type="number"
            name="offer"
            value={formData.offer}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Description 1</label>
          <textarea
            name="description1"
            value={formData.description1}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Minimum Quantity</label>
          <input
            type="number"
            name="minQty"
            value={formData.minQty}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Delivery Time</label>
          <input
            type="text"
            name="deliveryTime"
            value={formData.deliveryTime}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Brand</label>
          <input
            type="text"
            name="brand"
            value={formData.brand}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Collection Names</label>
          <div className="collection-input">
            {formData.collectionNames.split(',').filter(Boolean).map((collection, index) => (
              <div key={index} className="collection-item">
                <span className="collection-text">{collection}</span>
                <button type="button" className="remove-collection" onClick={() => handleRemoveFromCollection(collection)}>&times;</button>
              </div>
            ))}
            <select
              value=""
              onChange={handleCollectionChange}
              onKeyDown={handleKeyDown}
              ref={collectionInputRef}
            >
              <option value="">Select Collection</option>
              {collections.map((collection, index) => (
                <option key={index} value={collection}>{collection}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="form-group">
          <label>Images</label>
          <input type="file" multiple onChange={handleImageUpload} />
          <div className="images-preview">
            {formData.images.map((image, index) => (
              <div key={index} className="image-preview">
                <img src={image} alt={`Product ${index}`} />
                <button type="button" className="remove-image" onClick={() => handleRemoveImage(image)}>
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>
        <SizeForm sizes={formData.sizes} setFormData={setFormData} productId={product?.id} />
        <ProductInfoForm productInfo={formData.productInfo} updateProductInfo={handleProductInfoChange} />
        <div className="form-actions" style={{justifyContent:'space-between'}}>
          <button   type="button" onClick={onCancel}>
            Cancel
          </button>
          <button className="cancel-btn"  type="submit" disabled={!hasChanges || isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
      {showConfirmationModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to save/update this product?</p>
            <button onClick={() => handleConfirmation(true)}>Yes</button>
            <button onClick={() => handleConfirmation(false)}>No</button>
          </div>
        </div>
      )}
      {notification && (
        <div className={`notification ${notification.includes('successfully') ? 'success' : 'error'}`}>
          {notification}
        </div>
      )}
       <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      
    </div>
  );
};

export default ProductForm;